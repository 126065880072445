import tableColumnsCache from "@/utils/tableColumnsCache"

const listVersion = 0
export const listName = 'sl.leilao.notas'
export const listStorage = tableColumnsCache(listName, [
    {label: 'Número', name: 'numero', active: true, sortable: true, ordering: 1},
    {label: 'ID', name: 'id', active: true, sortable: true, ordering: 1},
    {label: 'Emissão', name: 'data', active: true, sortable: true, ordering: 1},
    {label: 'Leilão', name: 'leilao', active: true, sortable: true, ordering: 2},
    {label: 'Lote(s)', name: 'lote', active: true, sortable: true, ordering: 3},
    {label: 'Bem', name: 'bem', active: true, sortable: true, ordering: 4},
    {label: 'Cliente', name: 'arrematante', active: true, sortable: true, ordering: 5},
    {label: 'Chave', name: 'chave', active: true, sortable: true, ordering: 5},
    {label: 'Valor', name: 'valor', active: true, sortable: true, ordering: 5},
    {label: 'Situação', name: 'status', active: true, sortable: true, ordering: 5},
], listVersion)
